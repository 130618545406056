export const tokenTypes = [
  "colors",
  "space",
  "fontSizes",
  "fonts",
  "fontWeights",
  "lineHeights",
  "letterSpacings",
  "sizes",
  "borderWidths",
  "borderStyles",
  "radii",
  "shadows",
  "zIndices",
  "transitions",
] as const;
